.App {
  text-align: center;
  min-height: 100vh;
  padding: 0 20px;
  padding-bottom: 50px;
  background-color: #5680b5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  margin: auto;
  height: 120px;
  padding-top: 20px;
}

.App-header img {
  width: 80vw;
  max-width: 420px;
  display: block;
  margin: 0 auto;
  height: 100px;
}

.App-link {
  color: #61dafb;
}

.App-coaching-images {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-column-gap: 20px;
  justify-items: center;
}

.App-coaching-images img {
  height: 400px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 0 0 20px;
}

.App-summary {
  margin: 0 0 50px;
  color: #fff;
}

.App-instructions {
  margin: 0 auto 50px;
  color: #fff;
  width: 80vw;
  max-width: 500px;
  display: block;
  text-align: center;
}

.App-instructions ol li {
  padding: 7px 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
